export type DurationObject = {
  years?: number;
  months?: number;
  weeks?: number;
  days?: number;
  hours?: number;
  minutes?: number;
};

const retrieveValue = (
  input: string,
  unitCharacter: "Y" | "M" | "W" | "D" | "H",
): number | undefined => {
  const regexp = new RegExp("(\\d+)([\\.,]\\d*)?" + unitCharacter);
  const match = input.match(regexp)?.[0];

  if (!match) return;

  return Number.parseFloat(
    match.replace(unitCharacter, "").replaceAll(",", "."),
  );
};

export const formatDuration = (ISO8601DurationString: string) => {
  const date = ISO8601DurationString.split("T")[0]?.slice(1) ?? "";
  const time = ISO8601DurationString.split("T")[1] ?? "";

  const years = retrieveValue(date, "Y");
  const months = retrieveValue(date, "M");
  const weeks = retrieveValue(date, "W");
  const days = retrieveValue(date, "D");
  const hours = retrieveValue(time, "H");
  const minutes = retrieveValue(time, "M");

  const output: DurationObject = {
    years,
    months,
    weeks,
    days,
    hours,
    minutes,
  };

  if (Object.values(output).every((value) => value === undefined)) return null;

  return output;
};
