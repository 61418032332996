import type { MaybeRef } from "@vueuse/core";

export const useCourseFormatLabel = (
  format: MaybeRef<DatabaseCompositeType<"course_format">>,
  options?: { i18n?: ReturnType<typeof useI18n> },
) => {
  const t = options?.i18n.t ?? useI18n().t;

  return computed(() => {
    const { amount, duration } = unref(format);

    const isFullDay = duration === "P1D";
    const isHalfDay = duration === "P0.5D";
    const isRecurring = (amount ?? 0) > 1;

    if (isFullDay && !isRecurring) return t("course.format.label.full_day");
    if (isHalfDay && !isRecurring) return t("course.format.label.half_day");

    const formattedDuration = formatDuration(duration ?? "");

    if (!formattedDuration) {
      useMonitoring().logError(new TypeError("Invalid duration : " + duration));

      return "";
    }

    const durationEntry = Object.entries(formattedDuration).find(
      (entry) => entry[1] !== undefined,
    );

    if (!durationEntry) return "";

    const quantity = isFullDay
      ? t("course.format.label.full_day")
      : isHalfDay
        ? t("course.format.label.half_day")
        : durationEntry[1] + durationEntry[0].charAt(0);

    return `${(amount ?? 0) > 1 ? `${amount} x ` : ""}${quantity}`;
  });
};
